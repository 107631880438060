export const COMMON_EVENTS = {
  FETCH_EVENT_GROUP_SUCCESS: 'FETCH_EVENT_GROUP_SUCCESS',
  FETCH_EVENT_GROUP_FAILURE: 'FETCH_EVENT_GROUP_FAILURE',
  SETTINGS_FETCH_SUCCESS: 'SETTINGS_FETCH_SUCCESS',
  SETTINGS_FETCH_FAILURE: 'SETTINGS_FETCH_FAILURE',
  SETTINGS_UPDATE_SUCCESS: 'SETTINGS_UPDATE_SUCCESS',
  SETTINGS_UPDATE_FAILURE: 'SETTINGS_UPDATE_FAILURE',
  FETCH_STUDENT_SUCCESS: 'FETCH_STUDENT_SUCCESS',
  FETCH_STUDENT_FAILURE: 'FETCH_STUDENT_FAILURE',
  FETCH_ORGANISATION_LIST_FAILURE: 'FETCH_ORGANISATION_LIST_FAILURE',
  FETCH_USER_PHONE_VALIDATIONS_FAILURE: 'FETCH_USER_PHONE_VALIDATIONS_FAILURE',
};
export const STOPS_EVENTS = {
  STOPS_LIST_FETCH_FAILURE: 'STOPS_LIST_FETCH_FAILURE',
  STOP_FETCH_FAILURE: 'STOP_FETCH_FAILURE',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  STOP_DELETE_FAILURE: 'STOP_DELETE_FAILURE',
  STOP_DELETE_SUCCESS: 'STOP_DELETE_SUCCESS',
  FETCH_LIST: 'FETCH_LIST',
  REFRESH: 'REFRESH',
  FETCH_ROUTE_STOP_MAPPING_FAILURE: 'FETCH_ROUTE_STOP_MAPPING_FAILURE',
};

export const CAMPUS_EVENTS = {
  CAMPUS_LIST_FETCH_FAILURE: 'CAMPUS_LIST_FETCH_FAILURE',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
};

export const ROUTES_EVENTS = {
  ROUTE_FETCH_FAILURE: 'ROUTE_FETCH_FAILURE',
  ROUTE_SAVE_SUCCESS: 'ROUTE_SAVE_SUCCESS',
  ROUTE_SAVE_FAILURE: 'ROUTE_SAVE_FAILURE',
  ROUTES_LIST_FETCH_FAILURE: 'ROUTES_LIST_FETCH_FAILURE',
};

export const STUDENTS_EVENTS = {
  STUDENTS_PDF_GENERATION_FAILURE: 'STUDENTS_PDF_GENERATION_FAILURE',
  STUDENTS_PDF_GENERATION_SUCCESS: 'STUDENTS_PDF_GENERATION_SUCCESS',
  FETCH_LIST: 'FETCH_LIST',
  REFRESH: 'REFRESH',
};

export const TRANSPORT_EVENTS = {
  FETCH_STOPS_ARRIVAL_TIMES: 'FETCH_STOPS_ARRIVAL_TIMES',
  FAILURE_FETCH_STOPS_ARRIVAL_TIMES: 'FAILURE_FETCH_STOPS_ARRIVAL_TIMES',
  FETCH_TRANSPORT_LIST_FAILURE: 'FETCH_TRANSPORT_LIST_FAILURE',
};

export const AUDIT_EVENTS = {
  CHANGE_AUDIT_LOGS_FAILUR: 'CHANGE_AUDIT_LOGS_FAILUR',
};

export const PLUGIN_EVENTS = {
  PLUGIN_ACTIVATE_FAILURE: 'PLUGIN_ACTIVATE_FAILURE',
  PLUGIN_ACTIVATE_SUCCESS: 'PLUGIN_ACTIVATE_SUCCESS',
};

export const ADMIN_EVENTS = {
  ORGANISATION_ROLE_FETCH_FAILURE: 'ORGANISATION_ROLE_FETCH_FAILURE',
  ORGANISATION_ROLE_FETCH_SUCCESS: 'ORGANISATION_ROLE_FETCH_SUCCESS',
};

export const FILE_HANDLING_EVENTS = {
  PDF_GENERATION_FAILURE: 'PDF_GENERATION_FAILURE',
};


export const CONTENT_TYPE_EVENTS = {
  FETCH_CONTENT_TYPE_LIST_FAILURE: 'FETCH_CONTENT_TYPE_LIST_FAILURE',
};